@import '../../../../styles/colors.scss'; 

.wrapper {
  width: 33%;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper p:not(:last-child) {
  margin-bottom: 12px;
}
.title {
  font-size: 26px;
}
.description {
  max-height: 50vh;
  overflow-y: scroll;
}
.okBtn {
  margin: 0 4px;
}
.okBtn:hover {
  cursor: pointer;
  background-color: $tone-6;
}
.isAdultBox {
  padding: 0;
}

@media (max-width: 1200px) {
  .wrapper {
    width: 50%;
  }
}
@media (max-width: 900px) {
  .wrapper {
    width: 90%;
  }
}
