@import '../../../../styles/colors.scss';

.wrapper {
  width: 60%;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  background-color: $tone-0 !important;
  border-radius: 0 !important;
}
.name {
  overflow-wrap: anywhere;
}
.name, .phone {
  text-align: center;
}
.phone {
  color: $gold;
}
.slider {
  margin-top: 16px;
}
.video {
  height: 98vh;
  width: 100%;
  margin-top: 16px;
}
.showVideoBtn {
  margin-top: 16px;
}
.description {
  padding: 8px 0;
  margin-top: 16px;
}
.closeBtn {
  position: fixed;
  z-index: 99;
}
.closeBtn:hover {
  background-color: $brown;
}

@media (max-width: 1200px) {
  .wrapper {
    width: 94%;
    height: 94%;
    border-radius: 16px !important;
  }
  .showVideoBtn {
    width: 100%;
  }
  .closeBtn {
    bottom: calc(30% - 18px);
    right: 0.3rem;
  }
}
@media (min-width: 1200px) {
  .closeBtn {
    top: 8px;
    right: 8px;
  }
}
