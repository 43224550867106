.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}
.title {
  font-family: 'Pacifico';
  font-weight: 400;
  font-size: 32px;
  user-select: none;
}
.title:hover {
  cursor: pointer;
}
.logo {
  width: 300px;
}
.caption {
  height: 96px;
}

@media (max-width: 1200px) {
  .logo {
    width: 200px;
    margin-left: 8px;
  }
  .wrapper {
    justify-content: left;
  }
}
